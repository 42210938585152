.settingsContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: black;
    overflow-y: hidden;
}

.container {
    padding: 3rem;
    padding-bottom: 1rem;
    display: flex;
    width: 100%;
}

.orgInfoContainer {
    display: flex;
    align-items: center;
}

.orgName {
    margin-left: 1.875rem;
    font-size: 1.875rem;
    color: white;
    font-weight: 800;
}

.logOutButtonContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
}


.logOutText {
    margin-right: 1.875rem;
    font-size: 1.5rem;
    color: white;
    font-weight: 300;
}

.logOutIconWrapper {
    height: 6.25rem;
    width: 6.25rem;
    border-radius: 6.25rem;
    background-color: #27272A;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
}

.categoryHeaderContainer {
    display: flex;
    flex-direction: column;
}

.categoryHeader {
    font-size: 3.125rem;
    color: white;
    font-weight: 800;
}

.categorySubheader {
    font-size: 1.5rem;
    color: white;
    font-weight: 300;
    margin-left: 0.188rem;
    margin-top: 0.625rem;
}

.toggleCardContainer {
    width: 100%;
    height: 4.375rem;
    background-color: #18181B;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    padding: 1.3rem;
    display: flex;
    outline: none;
}

.toggleCardContainer :focus{
    outline: none;
}

.optionName {
    font-size: 1.875rem;
    color: white;
    font-weight: 600;
    width: 100%;
}

.optionNameInactive {
    font-size: 1.875rem;
    color: #71717a;
    font-weight: 600;
    width: 100%;
}

.toggleSwitchWrapper {
    display: flex;
    align-items: center;
}

.logOutIcon {
    width: 4rem;
    height: 4rem;
}


.settingsOptionsContainer {
    height: 100vh; 
    width: 100%;
    display: flex;
}

.settingsOptionsPadding{
    padding: 3rem 12rem 10rem;
}

.settingsFooterPadding{
    padding: 3rem 12rem;
}

.settingsOptionsLeftCol {
    width: 50%;
    padding-right: 5rem;
}

.settingsOptionsRightCol {
    width: 50%;
    padding-left: 5rem;
    z-index: 2;
}


.settingsCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.604rem 0.987rem 1.604rem 1.604rem;
    background-color: #27272a;
    border-radius: 0.5rem;
    position: relative;
    margin-bottom: 1.5rem;
    outline: none;
}


.settingsCardSelected {
    outline: 0.25rem solid #2DD4BF;
    outline-offset: -0.25rem;
}

.settingsCardDisabled {
    color: #71717A;
}

.settingsCardSingle {
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    align-items: start;
    justify-content: start;
    text-align: left;
}

.settingsCardCenter {
    align-items: center;
    justify-content: center;
    text-align: center;
}

.settingsCardTitle {
    font-size: 1.875rem;
    font-weight: 800;
    color: #71717a;
    line-height: 1;
}

.settingsCardTitleSelected {
    color: #fff;
}

.settingsSubTitle {
    font-size: 1.5rem;
    font-weight: 300;
    color: #71717a;
    margin-top: 0.617rem;
}

.settingsSubTitleSelected {
    color: #fff;
}

.settingCheckCircleIcon {
    position: absolute;
    right: 13px;
}

.focusedCard {
    outline: 0.25rem solid #2DD4BF;
}

.playButtonDisabled {
    background-color: #71717a !important;
}

.iconActive{
    color: white;
}

.iconInactive{
    color: #71717a;
}

.pointer{
    cursor: pointer;
}